import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  // Checkbox,
  // FormControlLabel,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import AddPredictors from "components/predictors/AddPredictors";

// const defaultCategories = [
//   { label: "Քաղաքական", value: "politic" },
//   { label: "Սոցիալական", value: "social" },
//   { label: "Տնտեսական", value: "economic" },
//   { label: "Մարգարեական", value: "prophetic" },
//   { label: "Ֆինանսական", value: "finance" },
//   { label: "Սպորտային", value: "sport" },
// ];

const PredictorsDialog = ({
  items,
  selected,
  onChange,
  open,
  onClose,
  isLoading,
  getNextPage,
  page,
  setPage,
  hasMoreItems,
  label,
  // category,
  // setCategory = () => {},
  // categories = defaultCategories,
}) => {
  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogTitle>
        <div className="flex justify-between">
          {label}
          <Button onClick={onClose}>
            <IoClose color="gray" size={30} />
          </Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {/* {categories.map((cat) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={category === cat.value}
                  color="primary"
                  onChange={(e) =>
                    setCategory(
                      category === e.target.value ? "" : e.target.value
                    )
                  }
                  value={cat.value}
                />
              }
              label={cat.label}
              key={cat.value}
            />
          );
        })} */}
        <AddPredictors
          selected={selected}
          onChange={onChange}
          items={items}
          isLoadingNextPage={isLoading}
          hasMoreItems={hasMoreItems}
          getNextPage={getNextPage}
          page={page}
          setPage={setPage}
        />
      </DialogContent>
    </Dialog>
  );
};

export default PredictorsDialog;
