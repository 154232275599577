import React from "react";
import { useTranslation } from "react-i18next";
import InfiniteScrollTrigger from "components/infinite-scroll/InfiniteScrollTrigger";
import PredictorCard from "components/card/PredictorCard";
import { skeletonGenerator } from "components/infinite-scroll/helpers";
import ProgramSkeleton from "components/infinite-scroll/skeletons/ArticleSkeleton";

const AddPredictors = ({
  selected,
  onChange,
  items,
  isLoadingNextPage,
  hasMoreItems,
  getNextPage,
  page,
  setPage,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="w-full h-full overflow-y-auto"
      style={{
        background: "#e2e2e2",
      }}
    >
      <div className="m-auto">
        <div className="p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full">
          {items.map((item) => {
            const {
              firstName,
              lastName,
              image,
              description,
              degree,
              id,
              categories,
            } = item;
            const isSelected = selected.id === id;
            return (
              <PredictorCard
                key={id}
                firstName={firstName}
                lastName={lastName}
                image={image}
                degree={degree}
                categories={categories}
                description={description}
                primaryButtonText={
                  isSelected ? t("main.remove") : t("main.add")
                }
                showSecondaryButton={false}
                onPrimaryButtonClick={() => {
                  onChange(item);
                }}
                primaryButtonColor={isSelected ? "error" : "primary"}
              />
            );
          })}
          {isLoadingNextPage && skeletonGenerator(<ProgramSkeleton />)}
          <InfiniteScrollTrigger
            isLoading={isLoadingNextPage}
            hasMoreItems={hasMoreItems}
            callback={getNextPage}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>
    </div>
  );
};

export default AddPredictors;
