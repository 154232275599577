import { useFormik } from "formik";
import { predictorFormSchema } from "libs/validation/schemas";
import mediaUploader from "api/mediaUploader.api";
import useAuth from "hooks/useAuth";
import { CACHE_SIZE_UNLIMITED } from "firebase/firestore";

const FIELDS = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  DESCRIPTION: "description",
  IMAGE: "image",
  DEGREE: "degree",
  CATEGORIES: "categories",
  CACHE_SIZE_UNLIMITED,
};

const usePredictorForm = (props) => {
  const { predictor, create, update, id } = props;
  const { currentUser } = useAuth();
  const predictorForm = useFormik({
    initialValues: {
      [FIELDS.FIRST_NAME]: predictor.firstName || "",
      [FIELDS.LAST_NAME]: predictor.lastName || "",
      [FIELDS.IMAGE]: predictor.image || "",
      [FIELDS.DESCRIPTION]: predictor.description || "",
      [FIELDS.DEGREE]: predictor.degree || "",
      [FIELDS.CATEGORIES]: predictor.categories || [],
    },
    enableReinitialize: true,
    validationSchema: predictorFormSchema,
    onSubmit: async (values) => {
      let imageUrl = "";
      let contentUrl = "";
      if (typeof values[FIELDS.IMAGE] !== "string") {
        imageUrl = await mediaUploader(
          values[FIELDS.IMAGE],
          `predictors/${id}/poster`
        );
      } else {
        imageUrl = values[FIELDS.IMAGE];
      }

      if (values[FIELDS.CONTENT]) {
        const blob = new Blob([values[FIELDS.CONTENT]], { type: "text/plain" });
        const file = new File([blob], "content.txt", { type: "text/plain" });
        contentUrl = await mediaUploader(file, `predictors/${id}/content`);
      }

      create({
        ...values,
        image: imageUrl,
        createdAt: Date.now(),
        content: contentUrl,
        author: { id: currentUser?.id, displayName: currentUser.displayName },
      });
      update(id, {
        ...values,
        image: imageUrl,
        content: contentUrl,
        updatedAt: Date.now(),
      });
    },
  });

  const { values, setFieldValue, handleSubmit, resetForm } = predictorForm;

  return {
    predictorForm,
    FIELDS,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    errors: predictorForm.errors,
  };
};

export default usePredictorForm;
