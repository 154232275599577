import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ImageAttachment from "components/image-cropper/ImageAttachment";
const DEFAULT_IMAGE_URL =
  "https://assets.promptbase.com/DALLE_IMAGES%2F0wbTCfH5rym9qX6gukTX%2Fresized%2F1690684977463_800x800.webp?alt=media&token=c39b1396-3bb9-493f-8829-e963804d6b52";

const categories = [
  { label: "Քաղաքական", value: "politic" },
  { label: "Սոցիալական", value: "social" },
  { label: "Տնտեսական", value: "economic" },
  { label: "Մարգարեական", value: "prophetic" },
  { label: "Ֆինանսական", value: "finance" },
  { label: "Սպորտային", value: "sport" },
];

const PredictorOverview = ({
  overview,
  errors,
  setFieldValue,
  deletePredictor,
  id,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-8 items-center w-full px-20 overflow-auto max-h-screen">
      <div className="w-3/4 flex flex-col gap-2">
        <TextField
          size="small"
          value={overview?.firstName || ""}
          onChange={(e) => setFieldValue("firstName", e.target.value)}
          fullWidth
          label="Անուն"
          error={Boolean(errors?.firstName)}
          helperText={t(errors?.firstName)}
        />
        <TextField
          size="small"
          value={overview?.lastName || ""}
          onChange={(e) => setFieldValue("lastName", e.target.value)}
          fullWidth
          label="Ազգանուն"
          error={Boolean(errors?.lastName)}
          helperText={t(errors?.lastName)}
        />
        <TextField
          size="small"
          value={overview?.degree || ""}
          onChange={(e) => setFieldValue("degree", e.target.value)}
          fullWidth
          label="Գիտական աստիճան"
          error={Boolean(errors?.degree)}
          helperText={t(errors?.degree)}
        />
        <TextField
          size="small"
          value={overview?.description || ""}
          onChange={(e) => setFieldValue("description", e.target.value)}
          fullWidth
          label={t("predictors.desc")}
          error={Boolean(errors?.description)}
          helperText={t(errors?.description)}
        />
      </div>
      <div className="grid grid-cols-3">
        {categories.map((category) => {
          return (
            <FormControlLabel
              key={category.value}
              control={
                <Checkbox
                  checked={overview?.categories?.includes(category.value)}
                  color="primary"
                  onChange={(e) =>
                    setFieldValue(
                      "categories",
                      overview?.categories?.includes(e.target.value)
                        ? overview?.categories?.filter(
                            (cat) => cat !== e.target.value
                          )
                        : [...(overview?.categories || []), e.target.value]
                    )
                  }
                  value={category.value}
                />
              }
              label={category.label}
            />
          );
        })}
      </div>
      <div className="flex gap-10 h-full">
        <ImageAttachment
          crop
          aspect={1}
          containerClassName="relative w-full"
          onSave={(file, url) => setFieldValue("image", file)}
          imageClassName="object-cover w-[960px] h-full"
          image={overview?.image}
          removeImage={() => setFieldValue("image", "")}
          defaultUrl={DEFAULT_IMAGE_URL}
        />
      </div>
      {id && (
        <Button
          color="error"
          variant="contained"
          className="mb-24"
          onClick={() => {
            setOpen(true);
          }}
        >
          Ջնջել կանխատեսողին
        </Button>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle> Հաստատում </DialogTitle>
        <DialogContent dividers>
          Դուք համոզված ե՞ք, որ կանխատեսողը պետք է ջնջվի։
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Չեղարկել
          </Button>
          <Button
            onClick={() => {
              deletePredictor();
              setOpen(true);
              navigate("/predictors");
            }}
          >
            Ջնջել
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PredictorOverview;
