import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPrograms,
  selectIsLoading,
  selectLastProgram,
  selectProgramCount,
} from "redux-toolkit/programs/selectors";
import {
  getProgramsAsync,
  getNextProgramsAsync,
} from "redux-toolkit/programs/actions";

const programCategories = [
  { value: "veritas", label: "Ճշմարտություն" },
  { value: "whatToDo", label: "Ի՞նչ Անել" },
  { value: "revelation", label: "Բացահայտված է" },
  { value: "fair", label: "Արդարը" },
  { value: "proverbial", label: "Առակն է խոսում" },
  { value: "churchStage", label: "Եկեղեցական Բեմ" },
];

const usePrograms = (category) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const programs = useSelector(selectPrograms);
  const isLoading = useSelector(selectIsLoading);
  const last = useSelector(selectLastProgram);
  const count = useSelector(selectProgramCount);

  useEffect(() => {
    dispatch(getProgramsAsync(category));
  }, [dispatch, category]);

  const getNextPage = () => {
    if (last && count > programs.length) {
      dispatch(getNextProgramsAsync({ last, category }));
      setPage(page + 1);
    }
  };

  return {
    programs,
    isLoading,
    hasMoreItems: count > programs.length,
    page,
    programCategories,
    setPage,
    getNextPage,
  };
};

export default usePrograms;
