import React from "react";
import { MdOutlineDragIndicator, MdDelete } from "react-icons/md";
import { Button, Typography } from "@mui/material";

const CATEGORIES = {
  veritas: "Ճշմարտություն",
  whatToDo: "Ի՞նչ Անել",
  revelation: "Բացահայտված է",
  fair: "Արդարը",
  proverbial: "Առակն է խոսում",
  analysis: "Վերլուծություն",
  event: "Իրադարձություն",
  special: "Հատուկ",
  churchStage: "Եկեղեցական Բեմ",
};

const Template = ({ item, provided, deleteItem }) => {
  const { id, title, poster, description, categories } = item;
  return (
    <div
      className="flex justify-between mt-4 between bg-white shadow-lg items-center rounded-md group"
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <div {...provided.dragHandleProps} className="max-w-4 mt-2 mx-2">
        <MdOutlineDragIndicator size={20} />
      </div>
      <div className="relative flex w-full h-44 hover:cursor-pointer">
        <img
          className="max-w-72 min-w-72 h-full object-cover rounded-l-lg"
          src={poster}
          alt={title}
        />
        <div className="absolute top-4 left-0 flex flex-col justify-center gap-1">
          {!!categories.length &&
            categories.map((key) => (
              <Typography
                className="bg-gray-800 bg-opacity-80 rounded-r-md p-2"
                variant="body2"
                color="white"
                key={key}
              >
                {CATEGORIES[key] || key}
              </Typography>
            ))}
        </div>
        <div className="flex flex-col px-6">
          <span className="font-bold text-sm line-clamp-2 mt-2 hover:underline">
            {title}
          </span>
          <span className="text-sm line-clamp-6 mt-2">{description}</span>
        </div>
      </div>
      <div className="flex max-w-4 items-center items-center cursor-pointer opacity-0 group-hover:opacity-100">
        <Button
          onClick={() => {
            deleteItem(id);
          }}
        >
          <MdDelete color="red" size={20} />
        </Button>
      </div>
    </div>
  );
};

export default Template;
