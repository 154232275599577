import { useFormik } from "formik";
import { articleFormSchema } from "libs/validation/schemas";
import mediaUploader from "api/mediaUploader.api";
import useAuth from "hooks/useAuth";
import { CACHE_SIZE_UNLIMITED } from "firebase/firestore";

const FIELDS = {
  TITLE: "title",
  DESCRIPTION: "description",
  POSTER: "poster",
  CONTENT: "content",
  CACHE_SIZE_UNLIMITED,
  CATEGORIES: "categories",
  VIDEO: "videoUrl",
  PUBLISHED: "published",
  PREDICTOR: "predictor",
};

const usePredictionForm = (props) => {
  const { prediction, create, update, id } = props;
  const { currentUser } = useAuth();
  const predictionForm = useFormik({
    initialValues: {
      [FIELDS.TITLE]: prediction.title || "",
      [FIELDS.POSTER]: prediction.poster || "",
      [FIELDS.DESCRIPTION]: prediction.description || "",
      [FIELDS.CONTENT]: prediction.content || "",
      [FIELDS.CATEGORIES]: prediction.categories || [],
      [FIELDS.VIDEO]: prediction.videoUrl || "",
      [FIELDS.PUBLISHED]: prediction.published || false,
      [FIELDS.PREDICTOR]: prediction.predictor || "",
    },
    enableReinitialize: true,
    validationSchema: articleFormSchema,
    onSubmit: async (values) => {
      let posterUrl = "";
      let contentUrl = "";
      if (typeof values[FIELDS.POSTER] !== "string") {
        posterUrl = await mediaUploader(
          values[FIELDS.POSTER],
          `predictions/${id}/poster`
        );
      } else {
        posterUrl = values[FIELDS.POSTER];
      }

      if (values[FIELDS.CONTENT]) {
        const blob = new Blob([values[FIELDS.CONTENT]], { type: "text/plain" });
        const file = new File([blob], "content.txt", { type: "text/plain" });
        contentUrl = await mediaUploader(file, `predictions/${id}/content`);
      }

      create({
        ...values,
        poster: posterUrl,
        createdAt: Date.now(),
        content: contentUrl,
        author: { id: currentUser?.id, displayName: currentUser.displayName },
      });
      update(id, {
        ...values,
        poster: posterUrl,
        content: contentUrl,
        updatedAt: Date.now(),
      });
    },
  });

  const { values, setFieldValue, handleSubmit, resetForm } = predictionForm;

  return {
    predictionForm,
    FIELDS,
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    errors: predictionForm.errors,
  };
};

export default usePredictionForm;
