import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import PredictorsDialog from "components/predictors/PredictorsDialog";
import usePredictors from "hooks/predictors/usePredictors";
import PredictorCard from "components/card/PredictorCard";

const AddPredictorsStep = ({
  category,
  setCategory,
  categories,
  selected,
  onChange,
}) => {
  const { predictors, isLoading, getNextPage, page, setPage, hasMoreItems } =
    usePredictors();
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  return (
    <div>
      <div className="mt-6 w-full flex flex-col gap-10 items-center justify-center">
        {selected && (
          <PredictorCard
            firstName={selected.firstName}
            lastName={selected.lastName}
            image={selected.image}
            degree={selected.degree}
            categories={categories}
            description={selected.description}
            showSecondaryButton={false}
          />
        )}
        <FaPlus
          onClick={() => setIsOpenDialog(true)}
          className="cursor-pointer"
          size={30}
          color="#7F7F7F"
        />
      </div>

      <PredictorsDialog
        open={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        items={predictors}
        selected={selected}
        onChange={(value) => {
          onChange(value);
          setIsOpenDialog(false);
        }}
        isLoading={isLoading}
        hasMoreItems={hasMoreItems}
        getNextPage={getNextPage}
        page={page}
        setPage={setPage}
        label="Կանխատեսողներ"
        category={category}
        setCategory={setCategory}
        categories={categories}
      />
    </div>
  );
};

export default AddPredictorsStep;
